<template>
	<div>
		<b-card>
			<h4>Questions</h4>
			<!-- <form-generator :model="componentData.content" :schema="game_schema" /> -->
			<dynamic-zone ref="question" item-collapse="last" :items="componentData.content.questions"
				:computeTitleFn="questionTitle"
				:default-data="{ first_content: null, second_content: null, answers: [], grammarCard: null }"
				:add-text="'Add Question'">
				<template v-slot="{ item: question }">
					<form-generator :model="question" :schema="content_form_schema" />
					<dynamic-zone ref="answer" item-collapse="last" :items="question.answers"
						:computeTitleFn="answerTitle" :default-data="{ content: null, is_correct: false }"
						:add-text="'Add Answer'">
						<template v-slot="{ item: answer }">
							<form-generator :model="answer" :schema="answer_form_schema" />
						</template>
					</dynamic-zone>
					<h4 class="mt-1">Grammar Card</h4>
					<select-grammar-card :grammar_card="question.grammarCard" :index="question.index"
						:key="`question_${question.index}`" @cardSelected="test" />
				</template>
			</dynamic-zone>
		</b-card>
	</div>
</template>
<script>
import SelectGrammarCard from './SelectGrammarCard.vue'
const content_form_schema = [
	{
		cols: 12, fields: [
			{ field: 'first_content', label: 'First Content', validate: { required: true } },
			{ field: 'second_content', label: 'Second Content', validate: { required: true } },
			{ field: 'audio', label: 'Audio' },
		]
	},
];
const answer_form_schema = [
	{
		cols: 12, fields: [
			{ field: 'content', label: 'Content', validate: { required: true } },
			{ field: 'is_correct', label: 'Is Correct?', input_type: 'switch' },
		]
	},
];
export default {
	components: {
		SelectGrammarCard
	},
	props: {
		componentData: { type: Object, required: true },
	},
	data() {
		return {
			content_form_schema,
			answer_form_schema,
			card_options: [],
			selected_card: null,
			filter: {},
		}
	},
	methods: {
		questionTitle(item) {
			return `Question ${item.index + 1}`
		},
		answerTitle(item) {
			return item.content || item.id
		},
		test({ question_index, selected_card }) {
			this.componentData.content.questions[question_index].grammarCard = selected_card
		}
	}
}
</script>