<template>
  <b-card>Unknown component: {{ componentData }}</b-card>
</template>

<script>
export default {
  props: {
    componentData: { type: Object, required: true },
  },
};
</script>
