<template>
  <div>
    <div class="position-absolute" style="pointer-events: none;">
      <div v-if="picture_area" class="d-flex">
        <div class="ml-50">x: {{ picture_area.x }}% </div>
        <div class="ml-50">y: {{ picture_area.y }}% |</div>
        <div class="ml-50">width: {{ picture_area.width }}%</div>
        <div class="ml-50">height: {{ picture_area.height }}%</div>
      </div>
    </div>
    <div>
      <canvas ref="picture_canvas" @mousedown="startDrawing" @mouseup="stopDrawing" @mousemove="draw" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    picture: { type: String, default: null },
    startArea: { type: Object, default: null }
  },
  data() {
    return {
      area_data: null,
      isDrawing: false,
      startX: 0,
      startY: 0,
      endX: 0,
      endY: 0,
      canvas: null,
      canvas_rect: null,
      ctx: null,
      picture_area: null,
    }
  },
  mounted() {
    this.initCanvas();
    setTimeout(this.drawStartArea, 200);
  },
  methods: {
    startPickArea(item_area = {}) {
      this.area_data = item_area;
      this.modal_visible = true;
    },
    initCanvas() {
      this.canvas = this.$refs.picture_canvas;
      this.ctx = this.canvas.getContext("2d");

      let image = new Image();
      image.src = this.picture;
      image.onload = () => {
        // Set the canvas width and height to fit the container width while maintaining the aspect ratio
        const aspectRatio = image.width / image.height;
        this.canvas.width = this.canvas.parentElement.offsetWidth;
        this.canvas.height = this.canvas.width / aspectRatio;

        // Set the image as the background of the canvas using CSS background-image property
        this.canvas.style.backgroundImage = `url(${this.picture})`;
        this.canvas.style.backgroundSize = 'cover';
      };
    },
    drawStartArea() {
      if(!this.startArea) { return; }
      this.getCanvasRect();
      let rect = this.canvas_rect;
      let { x, y, width, height } = this.startArea;
      this.startX = x*rect.width/100;
      this.startY = y*rect.height/100;
      this.endX = this.startX + width*rect.width/100;
      this.endY = this.startY + height*rect.height/100;
      this.drawRectangle();
    },
    getCanvasRect() {
      this.canvas_rect = this.canvas.getBoundingClientRect();
    },
    startDrawing(event) {
      this.isDrawing = true;
      this.getCanvasRect();
      const rect = this.canvas_rect;
      this.startX = event.clientX - rect.left;
      this.startY = event.clientY - rect.top;
    },
    stopDrawing(event) {
      this.isDrawing = false;
      const rect = this.canvas_rect;
      this.endX = event.clientX - rect.left;
      this.endY = event.clientY - rect.top;
      this.drawRectangle({ stop: true });
    },
    draw(event) {
      if (this.isDrawing) {
        const rect = this.canvas_rect;
        this.endX = event.clientX - rect.left;
        this.endY = event.clientY - rect.top;
        this.drawRectangle();
      }
    },
    drawRectangle({stop = false} = {}) {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.ctx.beginPath();
      let rec_width = this.endX - this.startX;
      let rec_height = this.endY - this.startY;
      this.ctx.rect(this.startX, this.startY, rec_width, rec_height);
      this.ctx.stroke();

      if(stop) {
        this.picture_area = {
          x: this.toRelativeX(this.startX),
          y: this.toRelativeY(this.startY),
          width: this.toRelativeX(rec_width),
          height: this.toRelativeY(rec_height),
        };
        this.$emit('pickArea', this.picture_area);
      }
    },
    toRelativeX(value) {
      let origin_value = this.canvas_rect.width;
      return Number((value*100/origin_value).toFixed(2));
    },
    toRelativeY(value) {
      let origin_value = this.canvas_rect.height;
      return Number((value*100/origin_value).toFixed(2));
    },
  }
}
</script>
