<template>
  <div>
    <h2 class="h2">Words</h2>
    <word-list :word-ids="componentData.content.words" :milestone-id="componentData.content.milestone" />
  </div>
</template>

<script>
import WordList from '../../../milestone/views/_components/WordList.vue'
export default {
  components: {
    WordList,
  },
  props: {
    componentData: {type: Object, required: true},
  },
};
</script>
