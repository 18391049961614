<template>
  <b-card>
    <h4>Game: Word-Connect</h4>
    <form-generator :model="componentData.content" :schema="game_schema" />
    <dynamic-zone ref="map_list" item-collapse="last"
      :items="componentData.content.maps"
      title_field="word"
      :default-data="{ word: 'word', mapsize: { r: 3, c: 3 } }"
      :enable-create-item="false"
    >
      <template v-slot="{ item }">
        <form-generator :model="item" :schema="[{ cols: 12, fields: [{ field: 'word', label: 'Word', validate: { required: true } }] }]"  />
        <h6>Map size (rows x cols)</h6>
        <form-generator :model="item.mapsize"
          :schema="[
            { cols: 6, fields: [{ field: 'r', label: 'Rows', input_type: 'number', validate: { type: 'number', default: 3 } }]},
            { cols: 6, fields: [{ field: 'c', label: 'Cols', input_type: 'number', validate: { type: 'number', default: 3 } }]},
          ]"
        />
      </template>
    </dynamic-zone>
    <word-select-box v-if="componentData.content.milestone" :milestone-id="componentData.content.milestone" @wordSelected="addWord" @wordAdded="addWord" />
  </b-card>
</template>

<script>
import WordSelectBox from '../../../milestone/views/_components/WordSelectBox.vue'

const game_schema = [
  {
    cols: 12, fields: [
      { field: 'move', label: 'Move', input_type: 'number', validate: { required: true, default: 1 } },
      { field: 'score', label: 'Score', input_type: 'number', validate: { required: true, default: 100 } },
      { field: 'hint', label: 'Hint', input_type: 'number', validate: { required: true, default: 1 } },
    ]
  },
];
export default {
  components: {
    WordSelectBox,
  },
  props: {
    componentData: {type: Object, required: true},
  },
  data() {
    return {
      game_schema,
    }
  },
  methods: {
    addWord(word) {
      let existed_word = this.componentData.content.maps.find(item => item.word_id === word._id);
      if (existed_word) {
        this.$store.dispatch('pushErrorNotify', { text: 'existed word!' });
        return;
      }
      this.$refs.map_list.addItem({ _id: word._id, word: word.title, word_id: word._id })
    },
  }
};
</script>
