<template>
  <div>
    <b-card>
      <h4>Game: Picture-Word</h4>
      <form-generator :model="componentData.content" :schema="game_schema" />
    </b-card>
    <h4>Words</h4>
    <dynamic-zone ref="word_list" item-collapse="last"
      :items="componentData.content.picture_words"
      title_field="word"
      :default-data="{ word: 'word', sentences: [] }"
      :enable-create-item="false"
    >
      <template v-slot="{item}">
        <dynamic-zone :items="item.sentences" title_field="left_part" :default-data="sentence_default"
          :compute-title-fn="(item_data) => `${item_data.left_part}  ${item.word}  ${item_data.right_part}`"
        >
          <template v-slot="sentenceProps">
            <div class="d-flex align-items-center">
              <b-form-input v-model="sentenceProps.item.left_part" />
              <div class="ml-1 border p-1">{{ item.word }}</div>
              <b-form-input class="ml-1" v-model="sentenceProps.item.right_part" />
              <b-button class="ml-1 btn-icon" variant="success" title="pick item area" @click.stop="startPickArea(sentenceProps.item)">
                <feather-icon icon="CropIcon" />
              </b-button>
            </div>
            <div v-if="sentenceProps.item.picture_area" class="d-flex justify-content-end">
              <div class="ml-50">x: {{ sentenceProps.item.picture_area.x }}% </div>
              <div class="ml-50">y: {{ sentenceProps.item.picture_area.y }}% |</div>
              <div class="ml-50">width: {{ sentenceProps.item.picture_area.width }}%</div>
              <div class="ml-50">height: {{ sentenceProps.item.picture_area.height }}%</div>
            </div>
          </template>
        </dynamic-zone>
      </template>
    </dynamic-zone>
    <word-select-box :milestone-id="componentData.content.milestone" @wordSelected="addWord" @wordAdded="addWord" />
    <b-modal
      v-model="picture_modal_visible"
      title="Pick Item Area"
      hide-footer
      size="lg" modal-class="modal-primary"
    >
      <pick-item-area ref="picture_area" :start-area="picture_start_area" :picture="componentData.content.picture" @pickArea="updateItemArea" />
    </b-modal>
  </div>
</template>

<script>
import WordSelectBox from '../../../milestone/views/_components/WordSelectBox.vue'
import PickItemArea from './PickItemArea.vue'
const game_schema = [
  { cols: 4, fields: [{ field: 'picture', label: 'Picture', input_type: 'image', validate: { required: true } }] },
  { cols: 8, fields: [
    { field: 'move', label: 'Move', input_type: 'number', validate: { required: true, default: 1 } },
    { field: 'score', label: 'Score', input_type: 'number', validate: { required: true, default: 100 } },
    { field: 'hint', label: 'Hint', input_type: 'number', validate: { required: true, default: 1 } },
  ]},
];

const sentence_schema = [
  { cols: 6, fields: [{ field: 'left_part', label: 'Left Part' }] },
  { cols: 6, fields: [{ field: 'right_part', label: 'Right Part' }] },
];
const sentence_default = {
  left_part: '',
  right_part: '',
  picture_area: { x: 0, y: 0, width: 0, height: 0 },
};

export default {
  components: {
    WordSelectBox,
    PickItemArea
  },
  props: {
    componentData: {type: Object, required: true},
  },
  data() {
    return {
      game_schema,
      sentence_schema,
      sentence_default,
      picture_modal_visible: false,
      editing_sentence: null,
      picture_start_area: null,
    }
  },
  methods: {
    addWord(word) {
      let existed_word = this.componentData.content.picture_words.find(item => item._id === word._id);
      if(existed_word) {
        this.$store.dispatch('pushErrorNotify', { text: 'existed word!' });
        return;
      }
      this.$refs.word_list.addItem({ _id: word._id, word: word.title, word_id: word._id })
    },
    startPickArea(sentence = {}) {
      this.editing_sentence = sentence;
      this.picture_start_area = sentence.picture_area;
      this.picture_modal_visible = true;
    },
    updateItemArea(area_data) {
      if(!this.editing_sentence || !this.editing_sentence.picture_area) { return }
      this.editing_sentence.picture_area = {...area_data};
      this.picture_modal_visible = false;
    }
  }
};
</script>
