<template>
	<vue-select v-if="card_options" class="vue-select1 mt-2" name="select1" :options="card_options"
		v-model="selected_card" label="label" :reduce="label => label.value" @input="selectedCard" :searchable="true"
		placeholder="Find & select" style="background-color: white" @search="searchingCard" />
</template>
<script>
import VueSelect from 'vue-select';
import service from '../../service';
export default {
	components: {
		VueSelect,
	},
	props: {
		grammar_card: { type: String, require: true },
		index: { type: Number, require: true }
	},
	data() {
		return {
			card_options: [],
			selected_card: this.grammar_card,
			filter: {},
			init_option: {}
		}
	},
	created() {
		this.initOption();
	},
	methods: {
		async getGrammarCards() {
			let query = this.filter
			if (this.filter.title) {
				query = {
					'title.translations.value': { $regex: this.filter.title, '$options': 'i' }
				}
			}
			const response = await service.getGrammarCardList({
				query,
				limit: 100,
			});
			const cards = response.list;
			if (cards.length) {
				this.card_options = cards.map((card) => {
					return {
						label: card.title.translations.find(item => item.language === 'en').value || card.title.translations[0].value,
						value: card._id
					}
				})
			}
		},
		searchingCard(title) {
			this.filter.title = title;
			this.getGrammarCards();
		},
		selectedCard() {
			this.$emit('cardSelected', { selected_card: this.selected_card, question_index: this.index })
		},
		async initOption() {
			const response = await service.getGrammarCardList({
				query: { _id: this.grammar_card },
			});
			const cards = response.list;
			if (cards.length) {
				this.init_option = {
					label: cards[0].title.translations.find(item => item.language === 'en').value || cards[0].title.translations[0].value,
					value: cards[0]._id
				}
				this.card_options = [this.init_option]
			}
		}
	}
}
</script>