<template>
  <div class="page-content" v-if="stage">
    <page-breadcrumb class="mb-3"
      title="Stage edit"
      :items="breadcrumbItems"
    />
    <section>
      <h2 class="h2">Overview</h2>
      <b-card class="mt-1">
        <b-row>
          <b-col cols="12">
            <strong class="h6">Title</strong>
            <b-form-input
              class="mb-1"
              type="text"
              :placeholder="'Stage '+(stage.display_order+1)"
              disabled
            />
            <strong class="h6">Tag IS</strong>
            <b-form-input v-model="stage.completed_tag"
              class="mb-1"
              type="number"
            />
            <template v-if="stage.content.milestone !== undefined">
            <strong class="h6">Milestone</strong>
            <b-form-select
              class="mb-2"
              v-model="stage.content.milestone"
              :options="milestoneOptions"
            />
            <template v-if="stage.type == 3">
            <strong class="h6">Game type</strong>
            <b-form-select
              class="mb-2"
              v-model="stage.content.game_type"
              :options="game_type_options"
            />
            </template>
            </template>
            <strong class="h6">Published ?</strong>
            <div class="d-flex mb-2">
              <b-form-checkbox
                class="custom-control-primary"
                name="is_free_checkbox"
                switch
                v-model="stage.is_published"
              />
              <strong>{{ stage.is_published ? "Yes" : "No" }}</strong>
            </div>
            <strong class="h6">Display order</strong>
            <b-form-input disabled
              class="mb-1"
              type="number"
              v-model="stage.display_order"
            />
          </b-col>
        </b-row>
      </b-card>
    </section>
    <section class="mb-3">
      <type-detail :component-data="stage" />
    </section>
    <action-buttons
      update-button
      back-button
      delete-button
      @update="update"
      :is-updating="isUpdating"
      @delete="deleteStage"
      :is-deleting="isDeleting"
    />
  </div>
</template>
<script>
import { STAGE_TYPES } from '../constant'
import TypeDetail from './_components/TypeDetail.vue'
import service from '../service'
import milestoneService from "../../milestone/service"
export default {
  components:{
    TypeDetail,
  },
  data(){
    return {
      STAGE_TYPES,
      stage: null,
      isUpdating: false,
      isDeleting: false,
      game_type_options:[
        {text: "Matching 1 Image & n Words", value: 1},
        {text: "Matching n-Images & n-Words", value: 2},
        {text: "Matching n-Images & 1-Word", value: 3}
      ],
      milestoneOptions: [],
    }
  },
  computed: {
    stage_id() {
      return this.$route.params.stage_id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: 'Levels',
          to: { name: 'stage_level-list' },
        },
        {
          text: `Milestone ${this.stage.milestone}`,
          to: { name: 'arena-milestone-detail', params: { milestone_id: this.stage.milestone } },
        },
        {
          text: `Stage ${this.stage.stage_number + 1}`, active: true
        },
      ];
      return items;
    },
  },
  created(){
    this.getStage();
  },
  methods:{
    async getStage() {
      this.stage = await service.get({ id: this.stage_id });
      if (this.stage.content.milestone !== undefined){
        this.getMilestone();
      }
    },
    async update() {
      this.isUpdating = true;
      const response = await service.update({
        data: JSON.stringify(this.stage),
      });
      if (response.data.statusCode === 200) {
        this.$store.dispatch("pushSuccessNotify", {
          text: "Successfully updated stage!",
        });
      }
      this.getStage();
      this.isUpdating = false;
    },
    async deleteStage() {
      this.isDeleting = true;
      const response = await service.delete({ id: this.stage_id });
      if (response.data.statusCode === 200) {
        this.$store.dispatch("pushSuccessNotify", {
          text: "Successfully deleted stage!",
        });
      }
      this.isDeleting = false;
      this.$router.push({ name: "arena-stage-list" });
    },
    async getMilestone(){
      this.milestoneOptions = [];
      let response = await milestoneService.getAll();
      response.list.forEach(x=>{
        this.milestoneOptions.push({
          text: "Milestone "+x.id,
          value: x._id
        })
      })
    }
  }
}
</script>
